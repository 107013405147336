import {Statistic, type AnimateProps} from '@primer/react-brand'
import type {PrimerComponentStatistic} from '../../../schemas/contentful/contentTypes/primerComponentStatistic'

export type ContentfulStatisticProps = {
  animate?: AnimateProps
  component: PrimerComponentStatistic
  className?: string
  headingElementSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export function ContentfulStatistic({
  component,
  animate,
  className,
  headingElementSize = 'h3',
}: ContentfulStatisticProps) {
  const {heading, size, variant, description, descriptionVariant} = component.fields

  return (
    <Statistic className={className} variant={variant} size={size} animate={animate}>
      <Statistic.Heading as={headingElementSize}>{heading}</Statistic.Heading>
      <Statistic.Description variant={descriptionVariant}>{description}</Statistic.Description>
    </Statistic>
  )
}

try{ ContentfulStatistic.displayName ||= 'ContentfulStatistic' } catch {}